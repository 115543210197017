<template>
  <div>
    <form @submit.prevent="acceptOwnerShip()">
      <div class="signUpPage-container">
        <SipupSlider />
        <div class="signUpPage-rightSide">
          <div class="signUpPage-rightSide-inner d-flex flex-column justify-content-center h-100">
            <b-alert :show="!!api.verify.error_message" variant="danger">{{api.verify.error_message}}</b-alert>
            <div class="mainHeading mt-16px">Ownership change!</div>
            <p class="mainText-20px mt-24px mb-24px">
              Enter password for gaining ownership of this account
            </p>
            <div class="mainInput-container mb-24px w-100"
              :class="{
                'itsValidationTime': (forms.verify.submitted && $v.forms.verify.password.$invalid) || api.verify.validation_errors.password,
              }"
            >
              <label>
                Enter password
                <template v-if="(forms.verify.submitted && $v.forms.verify.password.$invalid) || api.verify.validation_errors.password">
                  <span class="itsValidationTime-text" v-if="!$v.forms.verify.password.required">Password is required</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.verify.password.capitalalphabet">* Password required at least one capital character</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.verify.password.smallalphabet">* Password required at least one small character</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.verify.password.numbers">* Password required at least one integer character</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.verify.password.specialcharacter">* Password required at least one special character</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.verify.password.minLength">* Password is minimum {{$v.forms.verify.password.$params.minLength.min}} is required</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.verify.password.maxLength">* Password is maximum {{$v.forms.verify.password.$params.maxLength.max}} character</span>
                  <span class="itsValidationTime-text" v-for="(em,i) in api.verify.password.required" :key="i">{{em}}</span>
                </template>
              </label>
              <input v-model="forms.verify.password" :disabled="api.verify.send" />
            </div>
            <div class="PasswordLength-LineContainer mt-0 mb-16px">
              <label>Password strength</label>
              <div class="line-wrap">
                <div :class="`line-itself ${(passwordStrength/5 * 100) > 60 ? 'strong' : ''} `" :style="`width: calc(${passwordStrength/5 * 100}% + ${passwordStrength>0 ? '2' : '0'}px);`"></div>
              </div>
              <small class="line-text">{{passwordStrength | filterPasswordStrengthText}}</small>
            </div>
            <button class="fullBlackBTN" :disabled="api.verify.send">Submit</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { API } from '@/service'
import required from 'vuelidate/lib/validators/required'
import SipupSlider from '../components/SipupSlider.vue'
import minLength from 'vuelidate/lib/validators/minLength'
import maxLength from 'vuelidate/lib/validators/maxLength'
import { helpers } from 'vuelidate/lib/validators'
import { filterPasswordStrengthText } from '@/bootstrap/filters/filterPasswordStrengthText';
export default {
  name: 'AcceptOwnership',
  components: {
    SipupSlider,
  },
  data() {
    return {
      api: {
        verify: this.$app.api({
          error_message: true,
          validation_errors: true,
        }),
      },
      forms: {
        verify: this.$app.api({
          data: {
            password: '',
          },
        }),
      },
    }
  },
  computed: {
    token() {
      return this.$route?.params?.key ?? '' 
    },
    passwordStrength(){
      const password = this.forms.verify.password || ''
      return ~~[/[A-Z]/.test(password),/[0-9]/.test(password),/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(password),/[a-z]/.test(password),password.length > 11,].reduce((a, b)=>b ? a + 1 : a)
    },
    
  },
  validations: {
    forms: {
      verify: {
        password: {
          required: required,
          minLength: minLength(8),
          maxLength: maxLength(32),
          capitalalphabet: helpers.regex('capitalalphabet',/[A-Z]/),
          smallalphabet: helpers.regex('smallalphabet',/[a-z]/),
          numbers: helpers.regex('numbers',/[0-9]/),
          specialcharacter: helpers.regex('specialcharacter',/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/),
        },
      },
    },
  },
  filters: {
    filterPasswordStrengthText,
  },
  methods: {
    async acceptOwnerShip() {
      this.forms.verify.submitted=true
      this.$v.forms.verify.$touch()
      if(this.$v.forms.verify.$invalid || this.api.verify.send) return;
      try {
        this.api.verify.send=true
        this.api.verify.error_message=''
        this.api.verify.validation_errors={}
        await API.endpoints.auth.acceptOwnerShip({
          id: this.token,
          password: this.forms.verify.password,
        })
        this.$router.push({
          name: 'login'
        })
      } catch (ex) {
        this.api.verify.error_message=ex.own_message
        this.api.verify.validation_errors=ex.own_errors
      } finally {
        this.forms.verify.submitted=false
        this.api.verify.send=false
      }
    }
  },
}
</script>

<style>

</style>